var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accentColor":"primary"}},[_c('CCardBody',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nama Jabatan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"Nama Jabatan","placeholder":"Nama Jabatan","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.nama_jabatan),callback:function ($$v) {_vm.$set(_vm.form, "nama_jabatan", $$v)},expression:"form.nama_jabatan"}})]}}],null,true)})],1),(_vm.item)?_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":"Kode Jabatan","placeholder":"","readonly":""},model:{value:(_vm.form.kode),callback:function ($$v) {_vm.$set(_vm.form, "kode", $$v)},expression:"form.kode"}})],1):_vm._e()],1),_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"rules":"numeric","name":"Tunjangan Jabatan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"Tunjangan Jabatan","placeholder":"Tunjangan Jabatan","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.tunjangan_jabatan),callback:function ($$v) {_vm.$set(_vm.form, "tunjangan_jabatan", $$v)},expression:"form.tunjangan_jabatan"}})]}}],null,true)})],1),_c('CCol',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"rules":"numeric","name":"Angka Kredit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"Angka Kredit","placeholder":"Angka Kredit","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.angka_kredit),callback:function ($$v) {_vm.$set(_vm.form, "angka_kredit", $$v)},expression:"form.angka_kredit"}})]}}],null,true)})],1),_c('CCol',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"rules":"numeric","name":"Batas Usia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"Batas Usia Pensiun","placeholder":"Batas Usia Pensiun","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.bup),callback:function ($$v) {_vm.$set(_vm.form, "bup", $$v)},expression:"form.bup"}})]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CTextarea',{attrs:{"label":"Iktisar Jabatan","rows":"9"},model:{value:(_vm.form.ikhtisar_jabatan),callback:function ($$v) {_vm.$set(_vm.form, "ikhtisar_jabatan", $$v)},expression:"form.ikhtisar_jabatan"}})],1)],1),_c('CRow',[_c('CCol',{staticClass:"text-left",attrs:{"col":"6"}},[(_vm.loading)?_c('CSpinner',{attrs:{"color":"success","size":"sm"}}):_c('CButton',{staticClass:"px-4",attrs:{"color":"primary","type":"submit"}},[_vm._v("Simpan")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }